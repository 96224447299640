import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { TopMenuStyle } from './Style';

function TopMenu() {
  const path = '/';

  useLayoutEffect(() => {
    const active = document.querySelector('.hexadash-top-menu a.active');
    const activeDefault = () => {
      const megaMenu = active.closest('.megaMenu-wrapper');
      const hasSubMenuLeft = active.closest('.has-subMenu-left');
      if (!megaMenu) {
        active.closest('ul').previousSibling.classList.add('active');
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        active.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };
    window.addEventListener('load', active && activeDefault);
    return () => window.removeEventListener('load', activeDefault);
  }, []);

  const addParentActive = (event) => {
    document.querySelectorAll('.parent').forEach((element) => {
      element.classList.remove('active');
    });

    const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
    const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
    if (!megaMenu) {
      event.currentTarget.closest('ul').previousSibling.classList.add('active');
      if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
    } else {
      event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
    }
  };
  const currentUser = useSelector((payload) => payload.currentUser.data);
  const roles = currentUser?.tenantRoles?.map((i) => i.role?.toLowerCase()?.replace(' ', ''));
  return (
    <TopMenuStyle>
      <div className="hexadash-top-menu ltr:pl-[20px] rtl:pr-[20px] xl:ltr:pl-[10px] xl:rtl:pr-[10px]">
        <ul>
          <li className="">
            <Link to="/" className="parent">
              Dashboard
            </Link>
          </li>

          <li className="has-subMenu">
            <Link to="#" className="parent">
              Donor
            </Link>
            <ul className="subMenu">
              <li className="">
                <Link to={`${path}donor/lobby/waiting`}>Waiting in lobby</Link>
              </li>
              <li>
                <NavLink onClick={addParentActive} to={`${path}donor/house-client`}>
                  In-House Check-ins
                </NavLink>
              </li>
              <li>
                <NavLink onClick={addParentActive} to={`${path}donor/network-client`}>
                  Network Check-ins
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="">
            <Link to="/clients" className="parent">
              Clients
            </Link>
          </li>

          <li className="">
            <Link to="/driver" className="parent">
              Driver
            </Link>
          </li>

          <li className="has-subMenu">
            <Link to="#" className="parent">
              Others
            </Link>
            <ul className="subMenu">
              {/* <li className="">
                <Link to="/clearinghouse" className="parent">
                  Clearing House
                </Link>
              </li> */}

              <li className="">
                <Link to="/addUser" className="parent">
                  Staff
                </Link>
              </li>

              {/* <li className="">
                <Link to="/sap" className="parent">
                  SAP
                </Link>
              </li> */}
              <li className="">
                <Link to="/network/client" className="parent">
                  Network
                </Link>
              </li>
              <li className="">
                <Link to="/pool" className="parent">
                  Random Pool
                </Link>
              </li>
            </ul>
          </li>

          <li className="">
            <Link to="/donor/checkin" className="parent" target="_blank" rel="noopener noreferrer">
              Donor Check-In
            </Link>
          </li>
          <li className="">
            <Link to="/sap">Sap</Link>
          </li>
          <li className="">
            <Link to="/cases">Cases</Link>
          </li>
          <li className="">
            <Link to="/driver/test/result" className="parent ">
              Test result
            </Link>
          </li>
          {roles?.includes('tenantadmin') ? (
            <li className="">
              <Link to="/transactions" className="parent">
                Transactions
              </Link>
            </li>
          ) : null}
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default TopMenu;
